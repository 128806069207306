import { useState, useEffect } from "react";
import AppWrapper from "../components/AppWrapper";
import { Button, Card, createStyles, TextInput, Select } from "@mantine/core";
import {
  IconPlus,
  IconPlayerPlay,
  IconHeartPlus,
  IconHeartMinus,
  IconUser,
  IconUsers,
  IconChevronLeft,
  IconChevronRight
} from "@tabler/icons";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const useStyles = createStyles((theme) => ({
  button: {
    borderRadius: "4px",
    height: "60px",
    width: "auto",
    fontSize: "2vh",
    fontWeight: "bold",
  },
}));

const Home = () => {
  const { classes, theme } = useStyles();
  const [players, setPlayers] = useState([
    { name: "", id: uuidv4(), health: 3, placing: 0 },
    { name: "", id: uuidv4(), health: 3, placing: 0 },
  ]);
  const [teams, setTeams] = useState([
    { name: "", id: uuidv4(), health: 0, players: [], placing: 0 },
    { name: "", id: uuidv4(), health: 0, players: [], placing: 0 }
  ]);
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [gameType, setGameType] = useState(null);
  const [winner, setWinner] = useState(null);
  const [nextPlace, setNextPlace] = useState(0);
  const [originalPlayerOrder, setOriginalPlayerOrder] = useState(null);
  const [originalTeamOrder, setOriginalTeamOrder] = useState(null)
  const [teamsCreated, setTeamsCreated] = useState(false);

  const back = () => {
    setGameType(null)
    setTeamsCreated(false)
    setPlayers([
      { name: "", id: uuidv4(), health: 3, placing: 0 },
      { name: "", id: uuidv4(), health: 3, placing: 0 }
    ]);
    setTeams([
      { name: "", id: uuidv4(), health: 0, players: [], placing: 0 },
      { name: "", id: uuidv4(), health: 0, players: [], placing: 0 }
    ]);
  }

  // Add a new empty player
  const addPlayer = () => {
    const newPlayer = { name: "", id: uuidv4(), health: 3, placing: 0 };
    setPlayers([...players, newPlayer]);
  };

  // Add a new empty team
  const addTeam = () => {
    const newTeam = { name: "", id: uuidv4(), health: 0, players: [], placing: 0 };
    setTeams([...teams, newTeam]);
  }

  const updateTeam = (id, update) => {
    var updatedTeams = [...teams];
    const foundIndex = teams.findIndex((p) => p.id === id);

    update.forEach((pair) => {
      if (pair.field === "health" && pair.value < 0) {
        // ensure health can't decrease below 0
        return;
      }
  
      updatedTeams[foundIndex] = {
        ...updatedTeams[foundIndex],
        [pair.field]: pair.value
      };
    })

    // check for winner
    if (isGameRunning) {
      var stillPlaying = [];
      updatedTeams.forEach((p) => {
        if (p.health > 0) stillPlaying.push(p);
      });
  
      if (stillPlaying.length === 1) {
        updateTeam(stillPlaying[0].id, [{field: "placing", value: 1}])
        setWinner(stillPlaying[0].id);
        updatedTeams.sort((a, b) => a.placing - b.placing);
      } 
    }

    setTeams(updatedTeams);
  } 

  const updatePlayer = (id, update) => {
    var updatedPlayers = [...players];
    const foundIndex = players.findIndex((p) => p.id === id);

    update.forEach((pair) => {
      if (pair.field === "health" && pair.value < 0) {
        // ensure health can't decrease below 0
        return;
      }
  
      updatedPlayers[foundIndex] = {
        ...updatedPlayers[foundIndex],
        [pair.field]: pair.value
      };
    })

    // check for winner
    var stillPlaying = [];
    updatedPlayers.forEach((p) => {
      if (p.health > 0) stillPlaying.push(p);
    });

    if (stillPlaying.length === 1) {
      updatePlayer(stillPlaying[0].id, [{field: "placing", value: 1}])
      setWinner(stillPlaying[0].id);
      updatedPlayers.sort((a, b) => a.placing - b.placing);
    } 

    setPlayers(updatedPlayers);
  };

  const addPlayerToTeam = (teamName, playerName) => {
    let updatedTeams = [...teams];

    // remove player from all other teams
    updatedTeams.forEach((t, i)=> {
      const index = t.players.indexOf(playerName);

      if (index > -1) {
        t.players.splice(index, 1);
      }
    })

    let foundIndex = updatedTeams.findIndex((t) => t.name === teamName);
    let currentPlayers = updatedTeams[foundIndex].players;
    currentPlayers.push(playerName);
    updatedTeams[foundIndex] = {
      ...updatedTeams[foundIndex],
      "players": currentPlayers
    };

    setTeams(updatedTeams);
  }

  // player card is clicked
  const subtractLife = (player) => {
    var updatedHealth = player.health - 1;
    var placing = null; 

    if (updatedHealth === 0) {
      placing = nextPlace - 1;
      setNextPlace(placing);
    } 

    updatePlayer(player.id, [{field: "health", value: player.health - 1}, {field: "placing", value: placing}]);
  };

  const subtractTeamLife = (team) => {
    var updatedHealth = team.health - 1;
    var placing = null; 

    if (updatedHealth === 0) {
      placing = nextPlace - 1;
      setNextPlace(placing);
    } 

    updateTeam(team.id, [{field: "health", value: team.health - 1}, {field: "placing", value: placing}]);
  }

  const addLife = (player) => {
    if (player.health < 3) {
      updatePlayer(player.id, [{field: "health", value: player.health + 1}, {field: "placing", value: 0}]);
      if (winner !== null) {
        setWinner(null)
      }
    }
  };

  const addTeamLife = (team) => {
    updateTeam(team.id, [{field: "health", value: team.health + 1}, {field: "placing", value: 0}]);
    if (winner !== null) {
      setWinner(null)
    }
  }

  const goToPlayerOrder = () => {
    setTeamsCreated(true);
  }

  const setupGame = () => {
    if (gameType === "individual") {
      return (
        <div style={{ margin: "25px 0" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "25px 0",
              minHeight: "500px"
            }}
          >
            {players.map((p) => PlayerCard(p))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            <Button
              className={classes.button}
              style={{ marginRight: "20px" }}
              onClick={() => resetGame()}
            >
              RESET
            </Button>
            <Button className={classes.button} onClick={() => newGame()}>
              NEW GAME
            </Button>
          </div>
        </div>
      )
    }
    else {
      return (
        <div style={{ margin: "25px 0" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "25px 0",
            }}
          >
            {players.map((p) => TeamPlayerCard(p))}
          </div>
          <div style={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "25px 0",
              minHeight: "300px"
            }}
          >
            {teams.map((t) => TeamCard(t))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            <Button
              className={classes.button}
              style={{ marginRight: "20px" }}
              onClick={() => resetGame()}
            >
              RESET
            </Button>
            <Button className={classes.button} onClick={() => newGame()}>
              NEW GAME
            </Button>
          </div>
        </div>
      )
    }
  }

  const startGame = () => {
    // set start game state
    setOriginalPlayerOrder(players);
    setIsGameRunning(true);
  };

  const startTeamGame = () => {
    // set start game state
    setOriginalTeamOrder(teams);
    setIsGameRunning(true);
  }

  const resetGame = () => {
    if (gameType === "individual") {
      setPlayers(originalPlayerOrder);
    }
    else {
      setOriginalTeamOrder(originalTeamOrder);
    }

    setNextPlace(players.length + 1);
    setWinner(null);
  };

  const removePlayer = (player) => {
    var updatedPlayers = players.filter(e => e.id !== player.id)
    setPlayers(updatedPlayers);
  }

  const newGame = () => {
    setPlayers([
      { name: "", id: uuidv4(), health: 3, placing: 0 },
      { name: "", id: uuidv4(), health: 3, placing: 0 },
    ]);
    setTeams([
      { name: "", id: uuidv4(), health: 0, players: [], placing: 0 },
      { name: "", id: uuidv4(), health: 0, players: [], placing: 0 }
    ]);
    setTeamsCreated(false)
    setIsGameRunning(false);
    setGameType(null);
  };

  // game setup
  const getGameSetup = () => {
    if (gameType === "individual") {
      return (
        <div>
          <Button
            leftIcon={<IconChevronLeft />}
            className={classes.button}
            onClick={() => back()}
            style={{
              backgroundColor: "transparent",
              marginLeft: "-15px"
            }}
          >
            BACK
          </Button>
          <h2 className="content-title">Enter Players</h2>
          <ol className="player-section">
            {players.map((p, index) => {
              return (
                <li key={index}>
                  <div 
                    style={{
                      display: "flex",
                      paddingRight: "80px",
                      marginTop: "1.2vh"
                    }}
                  >
                    <TextInput
                      key={index}
                      placeholder="Enter Name"
                      onChange={(e) => {
                        updatePlayer(p.id, [{field: "name", value: e.currentTarget.value}]);
                      }}
                      style={{marginRight: index < 2 ? "50px" : 0}}
                      value={p.name ?? ""}
                    />
                    {
                      index > 1  ? (
                        <Button 
                          style={{
                            backgroundColor: "transparent", 
                            color: "#ff0f17", 
                            width: "50px", 
                            fontSize: "1.25rem", 
                            padding: "0"
                          }}
                          onClick={() => removePlayer(p)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      ) : <div></div>
                    }
                  </div>
                </li>
              );
            })}
          </ol>
          <div className="button-container">
            <div className="add-player">
              <Button
                leftIcon={<IconPlus />}
                className={classes.button}
                onClick={() => addPlayer({ name: "", id: uuidv4(), health: 3, placing: 0 })}
                disabled={players.length === 20}
                style={{marginRight: "30px"}}
              >
                ADD PLAYER
              </Button>
              <span
                style={{
                  fontSize: "20px",
                  flexWrap: "wrap",
                  visibility: players.length === 20 ? "visible" : "hidden"
                }}
              >
                Max number of players reached.
              </span>
            </div>
            <Button
              leftIcon={<IconPlayerPlay />}
              className={classes.button}
              onClick={startGame}
            >
              START GAME
            </Button>
          </div>
        </div>
      );
    }
    else if (gameType === "multiple") {
      if (teamsCreated) {
        return (
          <div>
          <Button
            leftIcon={<IconChevronLeft />}
            className={classes.button}
            onClick={() => back()}
            style={{
              backgroundColor: "transparent",
              marginLeft: "-15px"
            }}
          >
            BACK
          </Button>
          <h2 className="content-title">Enter Players</h2>
          <ol className="player-section">
            {players.map((p, index) => {
              return (
                <li key={index}>
                  <div 
                    style={{
                      display: "flex",
                      paddingRight: "80px",
                      marginTop: "1.2vh"
                    }}
                  >
                    <TextInput
                      key={index}
                      placeholder="Enter Name"
                      onChange={(e) => {
                        updatePlayer(p.id, [{field: "name", value: e.currentTarget.value}]);
                      }}
                      style={{marginRight: "50px" }}
                      value={p.name ?? ""}
                    />
                    <Select
                      placeholder="Pick Team"
                      data={teams.map(t => t.name)}
                      onChange={(e) => addPlayerToTeam(e, p.name)}
                      style={{marginRight: index < 2 ? "50px" : 0}}
                    />
                    {
                      index > 1  ? (
                        <Button 
                          style={{
                            backgroundColor: "transparent", 
                            color: "#ff0f17", 
                            width: "50px", 
                            fontSize: "1.25rem", 
                            padding: "0"
                          }}
                          onClick={() => removePlayer(p)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      ) : <div></div>
                    }
                  </div>
                </li>
              );
            })}
          </ol>
          <div className="button-container">
            <div className="add-player">
              <Button
                leftIcon={<IconPlus />}
                className={classes.button}
                onClick={() => addPlayer({ name: "", id: uuidv4(), health: 3, placing: 0 })}
                style={{marginRight: "30px"}}
              >
                ADD PLAYER
              </Button>
            </div>
            <Button
              leftIcon={<IconPlayerPlay />}
              className={classes.button}
              onClick={startTeamGame}
            >
              START GAME
            </Button>
          </div>
        </div>
        )
      }
      else {
        return (
          <div>
            <Button
              leftIcon={<IconChevronLeft />}
              className={classes.button}
              onClick={() => back()}
              style={{
                backgroundColor: "transparent",
                marginLeft: "-15px"
              }}
            >
              BACK
            </Button>
            <h2 className="content-title">Enter Teams</h2>
            <ol className="player-section">
              {teams.map((p, index) => {
                return (
                  <li key={index}>
                    <div 
                      style={{
                        display: "flex",
                        paddingRight: "80px",
                        marginTop: "1.2vh"
                      }}
                    >
                      <TextInput
                        key={`name_${index}`}
                        placeholder="Enter Name"
                        onChange={(e) => {
                          updateTeam(p.id, [{field: "name", value: e.currentTarget.value}]);
                        }}
                        style={{marginRight: "50px"}}
                        value={p.name ?? ""}
                      />
                      <TextInput
                        key={`health_${index}`}
                        placeholder="Enter Health"
                        onChange={(e) => {
                          updateTeam(p.id, [{field: "health", value: e.currentTarget.value}]);
                        }}
                        style={{marginRight: "50px"}}
                      />
                    </div>
                  </li>
                );
              })}
            </ol>
            <div className="button-container">
              <div className="add-player">
                <Button
                  leftIcon={<IconPlus />}
                  className={classes.button}
                  onClick={() => addTeam({ name: "", id: uuidv4(), health: 0, players: [], placing: 0 })}
                  style={{
                    marginRight: "30px",
                  }}
                >
                  ADD TEAM
                </Button>
              </div>
              <Button
                rightIcon={<IconChevronRight />}
                className={classes.button}
                onClick={goToPlayerOrder}
              >
                CONTINUE
              </Button>
            </div>
          </div>
        );
      }
    }
    else {
      return (
        <div style={{
          margin: "auto",
          width: "50%",
          textAlign: "center",
          paddingTop: "2rem"
        }}>
          <h2 className="content-title">Select Game Type</h2>
          <div style={{
            marginTop: "2rem"
          }}>
            <Button
              leftIcon={<IconUser />}
              className={classes.button}
              onClick={() => setGameType("individual")}
              style={{
                marginRight: "3rem",
                width: "30%"
              }}
            >
              INDIVIDUALS
            </Button>
            <Button
              leftIcon={<IconUsers />}
              className={classes.button}
              onClick={() => setGameType("multiple")}
              style={{
                width: "30%"
              }}
            >
              TEAMS
            </Button>
          </div>
        </div>
      );
    }
  }

  const PlayerCard = (player) => {
    // create the hearts
    const hearts = [];
    for (let i = 0; i < player.health; i++) {
      var key = `${player.id}-${i}`;
      hearts.push(
        <img key={key} src="hearts.png" alt="Heart" style={{ width: "5rem" }} />
      );
    }

    return (
      <Card
        key={player.id}
        style={{
          width: "17rem",
          minHeight: "300px",
          opacity: player.health === 0 && "0.2",
          marginTop: "26px",
          marginRight: "20px"
        }}
      >
        <h1
          style={{
            textAlign: "center",
            margin: "0 0 20px 0",
            fontSize: "2rem",
            overflowWrap: "anywhere"
          }}
        >
          {player.name}
        </h1>
        {winner === player.id ? (
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <img src="trophy1.png" alt="Trophy" style={{ width: "15rem" }} />
            <h1 style={{ fontSize: "50px", marginTop: 0, color: "green" }}>
              WINNER!
            </h1>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              bottom: "20px",
              width: "15rem",
            }}
          >
            <div style={{textAlign: "center", marginBottom: "15px"}}>{hearts}</div>
            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
              <Button
                style={{
                  backgroundColor: "#ff0f17",
                  width: "45%",
                  marginBottom: "20px",
                  height: "50px",
                  fontSize: "30px",
                }}
                onClick={() => {
                  if (player.health > 0) subtractLife(player);
                }}
              >
                <IconHeartMinus />
              </Button>
              <Button
                style={{
                  width: "45%",
                  marginBottom: "20px",
                  height: "50px",
                  fontSize: "30px",
                }}
                onClick={() => {
                  addLife(player);
                }}
              >
                <IconHeartPlus />
              </Button>
            </div>
          </div>
        )}
      </Card>
    );
  };

  const TeamCard = (team) => {
    const hearts = [];
    for (let i = 0; i < team.health; i++) {
      var key = `${team.id}-${i}`;
      hearts.push(
        <img key={key} src="hearts.png" alt="Heart" style={{ width: "5rem" }} />
      );
    }

    return (
      <Card
        key={team.id}
        style={{
          width: "auto",
          opacity: team.health === 0 && "0.2",
          marginTop: "26px",
          marginRight: "20px"
        }}
      >
        <h1
          style={{
            textAlign: "center",
            margin: "0 0 20px 0",
            fontSize: "2rem",
            overflowWrap: "anywhere"
          }}
        >
          {team.name}
        </h1>
        {winner === team.id ? (
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <img src="trophy1.png" alt="Trophy" style={{ width: "15rem" }} />
            <h1 style={{ fontSize: "50px", marginTop: 0, color: "green" }}>
              WINNERS!
            </h1>
          </div>
        ) : (
          <div>
            <div style={{textAlign: "center", marginBottom: "15px"}}>{hearts}</div>
            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
              <Button
                style={{
                  backgroundColor: "#ff0f17",
                  width: "45%",
                  marginBottom: "20px",
                  height: "50px",
                  fontSize: "30px",
                }}
                onClick={() => {
                  if (team.health > 0) subtractTeamLife(team);
                }}
              >
                <IconHeartMinus />
              </Button>
              <Button
                style={{
                  width: "45%",
                  marginBottom: "20px",
                  height: "50px",
                  fontSize: "30px",
                }}
                onClick={() => {
                  addTeamLife(team);
                }}
              >
                <IconHeartPlus />
              </Button>
            </div>
          </div>
        )}
      </Card>
    )
  }

  const TeamPlayerCard = (player) => {
    return (
      <Card
        key={player.id}
        style={{
          width: "17rem",
          maxHeight: "100px",
          marginTop: "26px",
          marginRight: "20px"
        }}
      >
        <h1
          style={{
            textAlign: "center",
            margin: "0 0 20px 0",
            fontSize: "2rem",
            overflowWrap: "anywhere"
          }}
        >
          {player.name}
        </h1>
      </Card>
    );
  }

  /********************** EFFECTS **********************/

  useEffect(() => {
    if (isGameRunning === false) {
      setNextPlace(players.length + 1);
    }
  }, [players]);

  /*****************************************************/

  return (
    <AppWrapper>
    {/********************** GAME RUNNING **********************/}
      {
        isGameRunning 
          ? (
              setupGame()
            ) 
          : (
              getGameSetup()
            )
      }
    </AppWrapper>
  );
};

export default Home;
