import { ActionIcon, useMantineColorScheme } from "@mantine/core";
import { IconSun, IconMoonStars } from "@tabler/icons";

const AppWrapper = ({ children }) => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  return (
    <>
      <div className="header">
        <div className="logo-wrapper center">
          <img className="main-logo" src="ocl-logo.png" alt="OCL Logo" width="320"/>
        </div>
        <div className="center">
          <h1 className="header-title">The Official Crud League</h1>
        </div>
      </div>
      <ActionIcon
          className="dark-light-mode"
          variant="outline"
          color={dark ? "yellow" : "blue"}
          onClick={() => toggleColorScheme()}
          title="Toggle color scheme"
          style={{ width: "auto", height: "auto", padding: "5px 8px" }}
        >
          {dark ? <IconSun size={30} /> : <IconMoonStars size={30} />}
        </ActionIcon>
      <div className="content">{children}</div>
    </>
  );
};

export default AppWrapper;
